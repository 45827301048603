import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const MenuTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicMenu
  const page = pageContent.data || {}

  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  /*const seo = {
    title: page.seo_title,
    description: page.seo_description,
    keywords: page.seo_keywords,
    no_robots: page.no_robots,
  }*/

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout topMenu={topMenu.data} footer={footer.data} activeDocMeta={activeDoc}>
      <div id="menu" className="bg-dark pb-5">
        {/*<div className="text-center mb-5">
          <h6 className="text-white">ENTDECKEN SIE</h6>
          <h1 className="text-primary">UNSER MENÜ</h1>
        </div>*/}
        <SliceZone slices={page.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query menuQuery {
    prismicMenu {
      _previewable
      data {
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...MenuDataBodyMenuItem
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(MenuTemplate)
